import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=75';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="75" height="88" alt="Comune di Cantagallo Logo" />
  </figure>
);

export default LogoFooter;
