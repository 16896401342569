import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Alert } from 'design-react-kit/dist/design-react-kit';

const messages = defineMessages({
  error: {
    id: 'error',
    defaultMessage: 'Errore',
  },
});

const Body = ({ data }) => {
  const intl = useIntl();

  return (
    <div className="block error ">
      <div className="public-ui">
        <Alert color="danger">
          <h2>{intl.formatMessage(messages.error)}</h2>
          <p>{data.message}</p>
        </Alert>
      </div>
    </div>
  );
};

export default Body;
