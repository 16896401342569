/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import ErrorView from '@package/components/Blocks/Error/View.jsx';
import ErrorEdit from '@package/components/Blocks/Error/Edit.jsx';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings.italiaThemeViewsConfig.imagePosition = 'documentBody'; // possible values: afterHeader, documentBody
  config.settings = {
    ...config.settings,
    matomoSiteId: 'Kj0dLxE0nL',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    siteProperties: {
      ...config.settings.siteProperties,
      siteTitle: 'Comune di Cantagallo',
      siteSubtitle: '',
      parentSiteTitle: 'Unione dei Comuni Val di Bisenzio',
      parentSiteURL: 'http://www.cmvaldibisenzio.it/',
      subsiteParentSiteTitle: 'Comune di Cantagallo',
      arLoginUrl: '/oidc/login',
      arLogoutUrl: '/oidc/logout',
      spidLogin: true, //se true, nella pagina di errore Unauthorized, mostra il pulsante per il login a Spid.
    },

    'volto-gdpr-privacy': {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2022-12-27T10:30:00+00:00',
        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
              'volto-gdpr-privacy'
            ].defaultPanelConfig.technical.choices.filter(
              (f) => f.config_key !== 'GANALYTICS',
            ),*/
          ],
        },
      },
    },
  };

  config.blocks.blocksConfig.error = {
    id: 'error',
    title: 'Error',
    view: ErrorView,
    edit: ErrorEdit,
    restricted: true,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 0,
  };

  return config;
}
